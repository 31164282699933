'use client';

import * as React from 'react';
import classNames from 'classnames';
import { Modifier, usePopper } from 'react-popper';
import { useRouter } from 'next/navigation';
import { useTranslation } from 'react-i18next';
import ControlCheckbox from '@/components/ui/ControlCheckbox';
import ControlInput, { IControlInputProps } from '@/components/ui/ControlInput';
import { Portal } from '@/components/ui/Portal';
import Overlay from '@/components/ui/Overlay';
import Block from '@/components/ui/Block';
import { useOverlay } from '@/components/hooks/useOverlay';
import LayoutOverlay from '@/components/ui/LayoutOverlay';
import { Overlays } from '@/components/ui/Overlays';
import Highlighter from '@/components/ui/Highlighter';
import LoadingContent from '../LoadingContent';
import Icon from '../Icon';
import styles from './styles.module.scss';

export type IControlMultiItem = {
  label: string;
  count?: number;
  value: string;
  options?: IControlMultiItem[];
  layout?: 'default' | 'mobile';
  searchExpand?: boolean;
  keyword?: string;
};

export interface IControlMultiProps {
  /** Options to render */
  options: IControlMultiItem[];
  /** Values */
  values: string[];
  /** placeholder */
  placeholder: string;
  /** Disabled status */
  disabled?: boolean;
  /** Focus handler */
  onFocus?: React.DOMAttributes<HTMLDivElement>['onFocus'];
  /** Blur handler */
  onBlur?: React.DOMAttributes<HTMLDivElement>['onBlur'];
  /** Change handler */
  onChange?: (value: string) => void;
  /** Keyup handler */
  onClick?: React.InputHTMLAttributes<HTMLDivElement>['onClick'];
  /** Color higlight for errors */
  hasError?: boolean;
  /** className to append */
  className?: string;
  /** Loading */
  loading?: boolean;
  /** Search field config */
  searchProps?: IControlInputProps;
  /** Dropdown same width as element */
  sameWidth?: boolean;
  /** Layout to use */
  layout?: 'default' | 'mobile';
  /** ID */
  id: string;
  /** Buttons for mobile layout */
  mobileButtons?: React.ReactNode;
}

const RenderItem: React.FunctionComponent<
  IControlMultiItem &
    Pick<IControlMultiProps, 'onChange' | 'values'> & {
      checked: boolean;
      searchExpand?: boolean;
    }
> = (props) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(props.searchExpand);

  const toggleExpand = () => {
    setExpanded((prev) => !prev);
  };

  React.useEffect(() => {
    if (!expanded && props.searchExpand) {
      setExpanded(true);
    } else if (expanded && !props.searchExpand) {
      setExpanded(false);
    }
  }, [props.searchExpand]);

  if (props.layout === 'mobile') {
    if (props.options && props.options.length > 0) {
      return (
        <div
          className={classNames(styles['control-multi__item'], {
            [styles['is-open']]: expanded,
          })}
        >
          <div className={styles['control-multi__item__element']}>
            <div
              className={classNames(styles['control-multi__item__element__label'], {
                [styles['has-options']]: props.options && props.options.length > 0,
                [styles['is-open']]: expanded,
              })}
              onClick={toggleExpand}
            >
              <div className={styles['control-multi__item__label']}>
                <div>
                  <Highlighter
                    text={props.label}
                    search={props.keyword || ''}
                  />
                </div>
              </div>
              {props.count && props.count > 0 ? <div className={styles['control-multi__item__count']}>{props.count}</div> : null}
            </div>
          </div>
          {expanded && props.options && props.options.length > 0 && (
            <div className={styles['control-multi__item__options']}>
              <RenderItem
                label={t('components.controlMulti.selectCategory')}
                value={props.value}
                count={props.options.map((v) => v.count || 0).reduce((partialSum, a) => partialSum + a, 0)}
                key={props.value}
                checked={props.values.filter((v) => v === props.value).length > 0}
                values={props.values}
                onChange={props.onChange}
                layout="mobile"
                keyword={props.keyword}
              />
              {props.options.map((child) => {
                return (
                  <RenderItem
                    key={child.value}
                    checked={props.values.filter((v) => v === child.value).length > 0}
                    values={props.values}
                    onChange={props.onChange}
                    layout={props.layout}
                    searchExpand={child.searchExpand}
                    keyword={props.keyword}
                    {...child}
                  />
                );
              })}
            </div>
          )}
        </div>
      );
    }
    return (
      <div
        className={classNames(styles['control-multi__item-mobile'], {
          [styles['is-open']]: expanded,
          [styles['is-checked']]: props.checked,
        })}
        onClick={() => {
          if (typeof props.onChange === 'function') {
            props.onChange(props.value);
          }
        }}
      >
        <div className={styles['control-multi__item-mobile__check']}>
          {props.checked && (
            <Icon
              width={20}
              height={20}
              kind="check"
            />
          )}
        </div>
        <div className={styles['control-multi__item__label']}>
          <Highlighter
            text={props.label}
            search={props.keyword || ''}
          />
        </div>
        {props.count && props.count > 0 ? <div className={styles['control-multi__item__count']}>{props.count}</div> : null}
      </div>
    );
  }

  return (
    <div
      className={classNames(styles['control-multi__item'], {
        [styles['is-open']]: expanded && props.options && props.options.length > 0,
      })}
    >
      <div className={styles['control-multi__item__element']}>
        <div className={styles['control-multi__item__element__checkbox']}>
          <ControlCheckbox
            checked={props.checked}
            onChange={() => {
              if (typeof props.onChange === 'function') {
                props.onChange(props.value);
              }
            }}
          />
        </div>
        <div
          className={classNames(styles['control-multi__item__element__label'], {
            [styles['has-options']]: props.options && props.options.length > 0,
            [styles['is-open']]: expanded,
          })}
          onClick={toggleExpand}
        >
          <div>
            <Highlighter
              text={props.label}
              search={props.keyword || ''}
            />
          </div>
        </div>
      </div>
      {expanded && props.options && props.options.length > 0 && (
        <div className={styles['control-multi__item__options']}>
          {props.options.map((child) => {
            return (
              <RenderItem
                key={child.value}
                checked={props.values.filter((v) => v === child.value).length > 0}
                values={props.values}
                onChange={props.onChange}
                layout={props.layout}
                searchExpand={child.searchExpand}
                keyword={props.keyword}
                {...child}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

const sameWidth: Modifier<'sameWidth'> = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect: ({ state }) => {
    state.elements.popper.style.width = `${state.elements.reference.getBoundingClientRect().width}px`;
  },
};

const limitedWidth: Modifier<'limitedWidth'> = {
  name: 'limitedWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    state.styles.popper.minWidth = `${state.rects.reference.width}px`;
    state.styles.popper.maxWidth = `${state.rects.reference.width * 2}px`;
  },
  effect: ({ state }) => {
    state.elements.popper.style.minWidth = `${state.elements.reference.getBoundingClientRect().width}px`;
    state.elements.popper.style.maxWidth = `min(calc(100vw - var(--gridunit) - var(--gridunit)), ${state.elements.reference.getBoundingClientRect().width * 2}px)`;
  },
};

/**
 * Regular HTML input field with extended functionality and styling. Use this within form row.
 */
const ControlMulti: React.FunctionComponent<IControlMultiProps> = (props) => {
  const router = useRouter();
  const { openOverlays } = React.useContext(Overlays);
  const { openOverlay, isOverlayOpen, zIndexOfOverlay, closeOverlay } = useOverlay(props.id);
  const [hasFocus, setHasFocus] = React.useState(false);
  const [dropOpen, setDropOpen] = React.useState(false);
  const wrapperRef = React.useRef<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);
  const popper = usePopper(wrapperRef.current, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
      {
        name: 'flip',
        enabled: false,
      },
      {
        name: 'preventOverflow',
        enabled: true,
      },
      ...(props.sameWidth ? [sameWidth] : [limitedWidth]),
    ],
  });

  const closeAllOverlays = () => {
    Array(openOverlays.length)
      .fill(0)
      .map(() => {
        router.back();
      });
  };

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target;
      if (dropOpen) {
        if (
          target &&
          wrapperRef.current &&
          !wrapperRef.current.contains(target as Node) &&
          popperElement &&
          !popperElement.contains(target as Node)
        ) {
          setDropOpen(false);
        }
      }
    };
    globalThis.addEventListener('click', handleClickOutside);
    return () => {
      globalThis.removeEventListener('click', handleClickOutside);
    };
  }, [dropOpen, popperElement, wrapperRef]);

  if (props.layout === 'mobile') {
    return (
      <React.Fragment>
        <div
          className={classNames(
            styles['control-multi-mobile'],
            {
              [styles['has-error']]: props.hasError,
              [styles['disabled']]: props.disabled,
            },
            props.className,
          )}
          onClick={(e) => {
            e.preventDefault();
            openOverlay();
          }}
          role="button"
          tabIndex={0}
        >
          <div className={styles['control-multi__placeholder']}>{props.placeholder}</div>
          {props.loading && (
            <span className={styles['control-multi__loader']}>
              <LoadingContent size="tiny" />
            </span>
          )}
          {props.values.length > 0 && (
            <div className={styles['control-multi__count']}>
              <div>{props.values.length}</div>
            </div>
          )}
          <Icon
            width={16}
            height={16}
            kind="arrow01-right"
            wrapperClassName={styles['control-multi__arrow']}
          />
        </div>
        <Overlay
          isOpen={isOverlayOpen}
          onClose={closeOverlay}
          layout="default"
          zIndex={zIndexOfOverlay}
          hideClose={true}
        >
          <LayoutOverlay
            size="filter"
            title={props.placeholder}
            onClose={closeOverlay}
            goBack={closeAllOverlays}
            titleTheme="lighter"
            footer={props.mobileButtons}
          >
            <Block theme="lightest">
              <div className={styles['control-multi__mobile']}>
                {props.searchProps && <ControlInput {...props.searchProps} />}
                <div className={styles['control-multi__mobile__list']}>
                  {props.options.map((option, i) => (
                    <RenderItem
                      key={i}
                      label={option.label}
                      value={option.value}
                      options={option.options}
                      values={props.values}
                      checked={props.values.filter((v) => v === option.value).length > 0}
                      onChange={props.onChange}
                      layout={props.layout}
                      count={option.count}
                      keyword={props.searchProps?.value}
                      searchExpand={option.searchExpand}
                    />
                  ))}
                </div>
              </div>
            </Block>
          </LayoutOverlay>
        </Overlay>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div
        className={classNames(
          styles['control-multi'],
          styles['size-default'],
          {
            [styles['has-error']]: props.hasError,
            [styles['disabled']]: props.disabled,
            [styles['focus']]: hasFocus,
          },
          props.className,
        )}
        onClick={(e) => {
          e.preventDefault();
          if (wrapperRef.current && !hasFocus) {
            wrapperRef.current.focus();
          }
        }}
        onFocus={(e) => {
          setHasFocus(true);
          if (typeof props.onFocus === 'function') {
            props.onFocus(e);
          }
        }}
        onBlur={(e) => {
          setHasFocus(false);
          if (typeof props.onBlur === 'function') {
            props.onBlur(e);
          }
        }}
        role="button"
        tabIndex={0}
        ref={wrapperRef}
      >
        <div
          className={styles['control-multi__holder']}
          onClick={() => {
            setDropOpen(!dropOpen);
          }}
        >
          <div className={styles['control-multi__placeholder']}>{props.placeholder}</div>
          {props.loading && (
            <span className={styles['control-multi__loader']}>
              <LoadingContent size="tiny" />
            </span>
          )}
          {props.values.length > 0 && (
            <div className={styles['control-multi__count']}>
              <div>{props.values.length}</div>
            </div>
          )}
          <Icon
            width={16}
            height={16}
            kind={dropOpen ? 'arrow01-up' : 'arrow01-down'}
            wrapperClassName={styles['control-multi__arrow']}
          />
        </div>
      </div>
      <Portal>
        <div
          className={classNames(styles['control-multi__drop'], {
            [styles['is-open']]: dropOpen,
          })}
          ref={setPopperElement}
          style={popper.styles.popper}
          {...popper.attributes.popper}
        >
          <div className={styles['control-multi__drop__inner']}>
            <div className={styles['control-multi__drop__list']}>
              {props.searchProps && <ControlInput {...props.searchProps} />}
              {props.options.map((option, i) => (
                <RenderItem
                  key={i}
                  label={option.label}
                  value={option.value}
                  options={option.options}
                  values={props.values}
                  checked={props.values.filter((v) => v === option.value).length > 0}
                  onChange={props.onChange}
                  layout={props.layout}
                  count={option.count}
                  searchExpand={option.searchExpand}
                  keyword={props.searchProps?.value}
                />
              ))}
            </div>
          </div>
        </div>
      </Portal>
    </React.Fragment>
  );
};

ControlMulti.displayName = 'ControlMulti';

export default ControlMulti;
